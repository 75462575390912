import * as React from 'react'
// import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

import NewScientistLogo from '../images/New_Scientist.svg'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Features from '../components/features'
import Quote from '../components/quote'
import Cta from '../components/cta'
import Gallery from '../components/gallery'
import Testimonials from '../components/testimonials'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero></Hero>
    <section id="the-exhibition">
      <div className="container container--content">
        <h1>THE EXHIBITION</h1>
        <p>
          <i>Doctor Who Worlds of Wonder: Where Science Meets Fiction</i> explores the science behind the global hit series <i>Doctor Who</i>. Visitors experience many of the Doctor’s adventures, come face to face with a selection of iconic characters and explore some of the worlds visited on screen, all whilst learning about the real life science behind some of those memorable moments.
        </p>

        <p>
          The eight zones set within this educational exhibition explore a
          diverse selection of exciting scientific topics and feature a wealth
          of iconic props and sets, and a unique collection of behind-the-scenes
          resource materials from the world’s longest-running science fiction
          show.
        </p>

        <p>
          Created in close collaboration with the team at BBC Studios along with
          advisors specialising in a broad range of scientific topics.
          This inspiring, interactive and all-encompassing exhibition has been
          designed especially for science centres, natural science museums and
          attractions. It is a must-see for families, school groups,{' '}
          <i>Doctor Who</i> fans, curious minds and future scientists, whether
          or not they have seen the TV show.
        </p>
      </div>
      <div className="container">
        <Features></Features>
      </div>
    </section>
    <section id="visitor-experience">
      <div className="container container--content ">
        <h2>VISITOR EXPERIENCE</h2>
        <p>
          From the show’s very beginning in 1963, science has played a part in
          the development of some of the key stories and characters in{' '}
          <i>Doctor Who</i>. Now visitors can explore a variety of these science
          topics, taking them on a journey from the furthest stars to the depths
          of the ocean. Find out if time travel is actually possible, and delve
          into the TARDIS Tech Room to examine the technological capabilities of
          the Doctor’s gadgets and gizmos. Uncover the secrets of black holes
          and wormholes, consider the possibilities of life on other planets,
          and discover the science behind why the TARDIS is bigger on the
          inside. A visit to the Monster Vault is an opportunity to come face to
          face with many of the Doctor’s terrifying alien adversaries – and
          learn about their connections with real science.{' '}
        </p>

        <p>
          The exhibition also explores life on Earth: how weird and wonderful
          nature has inspired ideas seen in <i>Doctor Who</i>, and how the
          natural world is often stranger than fiction. Science is rapidly
          advancing our understanding of the nature of the universe, and
          discovering more about ourselves and our precious Earth. But what
          might DNA manipulation, cloning and artificial intelligence mean for
          our future? Visitors can explore some of these scientific hot topics,
          all of which have featured in <i>Doctor Who</i> episodes.
        </p>
        <Quote></Quote>
      </div>
      <div className="container">
        <Gallery></Gallery>
      </div>
    </section>
    <section id="upcoming-venues">
      <div className="container">
        <h2>Venues</h2>
        <div className="upcoming-venues__block">
          <span className="upcoming-venues__venue">WORLD PREMIERE:</span>
          <p className="upcoming-venues__venue">
            WORLD MUSEUM LIVERPOOL - 27 MAY TO 30 OCT 2022
          </p>
          <button disabled className="upcoming-venues__venue">BOOK TICKETS</button>
        </div>
        <div className="upcoming-venues__block">
          {/*<span className="upcoming-venues__venue"></span>*/}
          <p className="upcoming-venues__venue">
            NATIONAL MUSEUM OF SCOTLAND, EDINBURGH - 9TH DECEMBER 2022 TO 1ST MAY 2023
          </p>
          <span
          >
            <button disabled className="upcoming-venues__venue">BOOK TICKETS</button>
          </span>
        </div>
        <div className="upcoming-venues__block">
          {/*<span className="upcoming-venues__venue"></span>*/}
          <p className="upcoming-venues__venue">
            TĀKINA WELLINGTON CONVENTION AND EXHIBITION CENTRE, NEW ZEALAND - 1ST JUNE 2024 TO 28TH OCT 2024
          </p>
          <span
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.wellingtonnz.com/visit/events/doctor-who-world-of-wonders"
            >
              <button className="upcoming-venues__link">BOOK TICKETS</button>
            </a>
          </span>
        </div>
      </div>
    </section>
    <section id="contact-us">
      <div className="container">
        <Cta></Cta>
      </div>
    </section>
    <section id="info">
      <div className="infoBlock container">
        <div className="infoBlock__image">
          <StaticImage
            src="../images/entrance-to-the-TARDIS.png"
            placeholder="blurred"
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt="Entrance to the TARDIS."
          />
        </div>
        <div className="infoBlock__text">
          <h2>
            The Power of <i>Doctor Who</i>
          </h2>
          <p>
            For almost 60 years <i>Doctor Who</i> has occupied a unique place in
            the television landscape. Much loved by viewers both young and old,
            it continues to explore mind-bending scientific ideas and advances,
            and inspires generations of scientists, engineers and artists.
          </p>
          <p>
            The iconic characters, monsters, stories, and settings of the show
            provide a perfect guide through the wondrous worlds of space, time,
            and life sciences.
          </p>
          <ul>
            <li>
              The world’s longest running sci-fi series – first televised in
              1963
            </li>
            <li>More than 860 episodes (and counting...)</li>
            <li>Broadcast in over 50 countries</li>
            <li>829m views / 1.8m subscribers on YouTube</li>
            <li>4,800,000+ likes on Facebook</li>
            <li>1,600,000+ followers on Twitter</li>
            <li>800,000+ followers on Instagram</li>
          </ul>
        </div>
      </div>

      <div className="infoBlock container">
        <div className="infoBlock__text">
          <h2>AWARD WINNING CREATIVE TEAM</h2>
          <p>
            Following their work on the hugely successful <i>Doctor Who
            Experience</i>{' '} in both London and Cardiff, Sarner International, a
            renowned international experiential design and production practice,
            worked closely with BBC Studios to create and produce the brand new
            touring exhibition, <i>Doctor Who Worlds of Wonder.</i>
          </p>
          <p>
            Paula Al-Lach, BBC Studios Events Director, Live Entertainment said
            that the team were “delighted to be working with Sarner again,
            following their creation of the <i>Doctor Who Experience</i>. It is
            really exciting to explore <i>Doctor Who</i> from this perspective
            and have the opportunity to take the exhibition to fans globally.”
          </p>
          <div className="infoBlock__logos">
            <a
              target="_blank"
              title="Sarner LTD"
              rel="noreferrer"
              href="https://sarner.com"
            >
              <StaticImage
                src="../images/sarner-logo@2x.png"
                placeholder="blurred"
                quality={95}
                objectFit="contain"
                formats={['auto', 'webp', 'avif']}
                alt="Sarner LTD Logo"
                className="logo__sarner"
              />
            </a>
            <a
              target="_blank"
              title="BBC Studios"
              rel="noreferrer"
              href="https://www.bbcstudios.com"
            >
              <StaticImage
                src="../images/bbc-studios-logo@2x.png"
                placeholder="blurred"
                quality={95}
                objectFit="contain"
                formats={['auto', 'webp', 'avif']}
                alt="BBC Studios Logo"
                className="logo__bbc"
              />
            </a>
          </div>
        </div>
        <div className="infoBlock__image">
          <StaticImage
            src="../images/prepare-to-come-face-to-face-with-a-dalek.png"
            placeholder="blurred"
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt="Prepare to come face-to-face with a Dalek. / Schoolchildren at the exhibition"
          />
        </div>
      </div>
    </section>
    <section id="quotes">
      <div className="container">
        <Testimonials></Testimonials>
      </div>
    </section>
    <section id="press">
      <h3>Featured in</h3>
      <div className="container">
        <div className="press__logo-row">
          <a
            target="_blank"
            title="New Scientist - Doctor Who Worlds of Wonder: The science behind the sci-fi"
            rel="noreferrer"
            href="https://www.newscientist.com/video/2322114-doctor-who-worlds-of-wonder-the-science-behind-the-sci-fi/"
          >

            <img
              src={NewScientistLogo}
              alt="New Scientist Logo"
              className="press__logo"
            />
          </a>
          <a
            target="_blank"
            title="ITV News - Liverpool exhibition to reveal science behind Doctor Who"
            rel="noreferrer"
            href="https://www.itv.com/news/granada/2022-05-27/dr-whos-tardis-drops-in-to-liverpool"
          >
            <StaticImage
              src="../images/itv-logo@2x.png"
              placeholder="blurred"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="ITV News Logo"
              className="press__logo"
            />
          </a>
          <a
            target="_blank"
            title="RadioTimes.com - Doctor Who: Worlds of Wonder exhibition to open in 2022"
            rel="noreferrer"
            href="https://www.radiotimes.com/tv/sci-fi/doctor-who-worlds-of-wonder-review-educational-inspirational-and-a-fans-dream/"
          >
            <StaticImage
              src="../images/radiontimes-logo@2x.png"
              placeholder="blurred"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="RadioTimes.com Logo"
              className="press__logo"
            />
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
